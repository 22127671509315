<template>

  <!-- TYPOGRAPHY -->

  <h2>Typography</h2>
  <p class="styleguide-p-sourcecode">
    <em>Source code: <code>scss/base/_type.scss</code></em>
  </p>

  <h3 class="styleguide-h3">Headings</h3>

  <!-- <h1>Heading 1</h1> -->
  <h1>Heading 1<br />on two lines</h1>
  <!-- <h2>Heading 2</h2> -->
  <h2>Heading 2<br />on two lines</h2>
  <!-- <h3>Heading 3</h3> -->
  <h3>Heading 3<br />on two lines</h3>
  <!-- <h4>Heading 4</h4> -->
  <h4>Heading 4<br />on two lines</h4>
  <!-- <h5>Heading 5</h5> -->
  <h5>Heading 5<br />on two lines</h5>
  <!-- <h6>Heading 6</h6> -->
  <h6>Heading 6<br />on two lines</h6>

  <h3 class="styleguide-h3">Body text</h3>

  <h4>Biggest p</h4>

  <p class="font-size-biggest">
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci a
    natus ex dolores, itaque voluptas quo! Nesciunt, aspernatur. A provident
    error, molestias dolore quos facilis, doloribus eaque tempora voluptas,
    voluptatem animi commodi.
  </p>

  <h4>Bigger p</h4>

  <p class="font-size-bigger">
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci a
    natus ex dolores, itaque voluptas quo! Nesciunt, aspernatur. A provident
    error, molestias dolore quos facilis, doloribus eaque tempora voluptas,
    voluptatem animi commodi libero dicta qui enim laborum accusamus!
    Aliquid eligendi, fugit optio dolorum nesciunt ut exercitationem
    molestias praesentium voluptate.
  </p>

  <h4>Big p</h4>

  <p class="font-size-big">
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci a
    natus ex dolores, itaque voluptas quo! Nesciunt, aspernatur. A provident
    error, molestias dolore quos facilis, doloribus eaque tempora voluptas,
    voluptatem animi commodi libero dicta qui enim laborum accusamus!
    Aliquid eligendi, fugit optio dolorum nesciunt ut exercitationem
    molestias praesentium voluptate. Magni at, provident culpa minima ex
    porro ipsum ut dignissimos nam commodi quis qui dolorum saepe
    perferendis beatae alias facere assumenda voluptatem eum suscipit
    architecto! Ea.
  </p>

  <h4>Default p</h4>

  <p>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci a
    natus ex dolores, itaque voluptas quo! Nesciunt, aspernatur. A provident
    error, molestias dolore quos facilis, doloribus eaque tempora voluptas,
    voluptatem animi commodi libero dicta qui enim laborum accusamus!
  </p>
  <p>
    Aliquid eligendi, fugit optio dolorum nesciunt ut exercitationem
    molestias praesentium voluptate. Magni at, provident culpa minima ex
    porro ipsum ut dignissimos nam commodi quis qui dolorum saepe
    perferendis beatae alias facere assumenda voluptatem eum suscipit
    architecto! Ea.
  </p>

  <h4>Small p</h4>

  <p class="font-size-small">
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci a
    natus ex dolores, itaque voluptas quo! Nesciunt, aspernatur. A provident
    error, molestias dolore quos facilis, doloribus eaque tempora voluptas,
    voluptatem animi commodi libero dicta qui enim laborum accusamus!
    Aliquid eligendi, fugit optio dolorum nesciunt ut exercitationem
    molestias praesentium voluptate. Magni at, provident culpa minima ex
    porro ipsum ut dignissimos nam commodi quis qui dolorum saepe
    perferendis beatae alias facere assumenda voluptatem eum suscipit
    architecto! Ea.
  </p>

  <h4>Smaller p</h4>

  <p class="font-size-smaller">
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci a
    natus ex dolores, itaque voluptas quo! Nesciunt, aspernatur. A provident
    error, molestias dolore quos facilis, doloribus eaque tempora voluptas,
    voluptatem animi commodi libero dicta qui enim laborum accusamus!
    Aliquid eligendi, fugit optio dolorum nesciunt ut exercitationem
    molestias praesentium voluptate. Magni at, provident culpa minima ex
    porro ipsum ut dignissimos nam commodi quis qui dolorum saepe
    perferendis beatae alias facere assumenda voluptatem eum suscipit
    architecto! Ea.
  </p>

  <h3 class="styleguide-h3">Links</h3>

  <p><a href="#">This is a link</a></p>

  <p>
    Lorem ipsum dolor sit amet consectetur <a href="#">inline link</a>.
    Veniam placeat consectetur eius, natus animi itaque rerum, adoloremque
    magnam totam, <a href="#">adipisci corrupti quaerat</a>. Animi libero
    harum repudiandae maxime nihil reprehenderit. Beatae?
  </p>

  <p><Btn href="https://google.fr" text="Link as a button" color="default"/></p>
  <p><Btn to="{ name: 'home' }" text="RouterLink as a button" color="default"/></p>

  <h3 class="styleguide-h3">Lists</h3>

  <h4>Unordered list</h4>

  <ul>
    <li>Item #1</li>
    <li>Item #2</li>
    <li>Item #3</li>
    <li>Item #4</li>
  </ul>

  <h4>Ordered list</h4>

  <ol>
    <li>Item #1</li>
    <li>Item #2</li>
    <li>Item #3</li>
    <li>Item #4</li>
  </ol>

  <h3 class="styleguide-h3">Horizontal rule</h3>

  <hr />

  <h3 class="styleguide-h3">Quotes</h3>

  <blockquote>
    <p>I do not fear computers. I fear the lack of them.</p>
    <cite>– Isaac Asimov</cite>
  </blockquote>

  <blockquote>
    <p>
      But I don't want comfort. I want God, I want poetry, I want real
      danger, I want freedom, I want goodness. I want sin.
    </p>
    <cite>– Aldous Huxley, Brave New World</cite>
  </blockquote>

  <h3 class="styleguide-h3">Code</h3>

  <h4>Inline code</h4>

  <p>
    Lorem ipsum <code>dolor sit amet</code> consectetur adipisicing elit.
    Laboriosam, deserunt dicta recusandae voluptas ab nam dignissimos cum
    obcaecati dolorem, explicabo aperiam temporibus repellendus id
    aspernatur nostrum? Quaerat <code>odit</code> pariatur fuga.
  </p>

  <h4>Pre & code</h4>

  <pre><code><!--
-->Lorem ipsum dolor sit amet
consectetur adipisicing elit.
Ducimus perspiciatis sit repellat eum ullam,
architecto itaque consequatur beatae.<!--
--></code></pre>

</template>

<script>
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'StyleguideTypographyView',

  components: {
    Btn,
  },
}
</script>
